import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import PurchaseNft from "./pages/PurchaseNft";
import MyNFT from "./pages/MyNFT";


function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/my-nft" element={<MyNFT/>} />
          <Route path="/purchase-nft" element={<PurchaseNft/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
