import { ethers } from "ethers";
import NordekNFT from "./abi.json";


export const checkAvailability = async (category) => {
  try {
    const contractAddress = "0x2F2860F9C46355bC398d63056716aE3aF4435d6f"; //Replace with your contract address
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    await provider.send("eth_requestAccounts", []);
    const signer = await provider.getSigner();
    const contract = new ethers.Contract(
      contractAddress,
      NordekNFT.output.abi,
      signer
    );
    const tx = await contract.checkAvailableNFTsInMatch(category);
    return tx;
  } catch (error) {
    alert("Transaction Rejected!");
  }
};
// export const checkAvailability = async (category) => {
//   try {
//     const contractAddress = "0x0838d1eBD7015Ff6089263Cae162Ab0cBdC34988"; //Replace with your contract address
//     const provider = new ethers.providers.Web3Provider(window.ethereum);
//     await provider.send("eth_requestAccounts", []);
//     const signer = await provider.getSigner();
//     const contract = new ethers.Contract(
//       contractAddress,
//       NordekNFT.output.abi,
//       signer
//     );
//     console.log(contract,'this ')
//     const tx = await contract.getAvailableTokensByCategory(category);
//     console.log(tx)
//     return tx;
//   } catch (error) {
//     alert("Transaction Rejected!");
//   }
// };

export const checkNFTSold = async (category) => {
  try {
    const contractAddress = "0x2F2860F9C46355bC398d63056716aE3aF4435d6f"; //Replace with your contract address
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    await provider.send("eth_requestAccounts", []);
    const signer = await provider.getSigner();
    const contract = new ethers.Contract(
      contractAddress,
      NordekNFT.output.abi,
      signer
    );
    const tx = await contract.getAvailableTokensByCategory(category);
    return tx;
  } catch (error) {
    //alert("Transaction Rejected!");
  }
};


export const Mint = async (category) => {
  try {
    
    const availability = await checkAvailability(category);
    if (availability > 0) {
      const contractAddress = "0x2F2860F9C46355bC398d63056716aE3aF4435d6f"; //Replace with your contract address
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      const signer = await provider.getSigner();
      const contract = new ethers.Contract(
        contractAddress,
        NordekNFT.output.abi,
        signer
      );
      const price = await ethers.utils.parseEther("4000");
      const tx = await contract.mintNFT(category, {
        gasLimit: 900000,
        value: price,
      });
      alert("Transaction Initiated");
      await tx.wait();
      alert("Transaction Successful");
      
      return tx.hash;
    } else if (availability === 0) {
      alert("NFTs sold out!");
    } else {
      alert("NFTs are not for sale right now!");
    }
  } catch (error) {
    alert("Something went wrong!");
    throw new Error()
  }
};

export const fetchUserNFTs = async () => {
  try {
    const contractAddress = "0x2F2860F9C46355bC398d63056716aE3aF4435d6f"; //Replace with your contract address
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    await provider.send("eth_requestAccounts", []);
    const signer = await provider.getSigner();
    const userAddress = await signer.getAddress();
    const contract = new ethers.Contract(
      contractAddress,
      NordekNFT.output.abi,
      signer
    );
    const tokenIds = await contract.getUserNFTs(userAddress);
    let tokenURIs = [];
    for (let i = 0; i < tokenIds.length; i++) {
      const tokenURI = await contract.tokenURI(tokenIds[i]);
      tokenURIs.push(tokenURI);
    }
    return tokenURIs;
  } catch (error) {
    return error;
  }
};
