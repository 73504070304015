import React from "react";
import dropboxImg from "../../assets/dropbox-img.png";

const Dropbox = () => {
  return (
    <div className="dropbox-section flex flex-col lg:flex-row items-center lg:justify-around mt-12 lg:mt-24">
      <div className="left-sdie mt-4 px-16 mb-10 lg:mb-0 lg:w-1/2">
        <div className="font-[Barlow] font-bold">
          <h1 className="heading">NORDEK'S</h1>
          <h1 className="subheading">NFT DROPBOX</h1>
        </div>
        <p>
          Witness the magic of
          <span> NFTs </span>
          as they disappear in the blink of an eye! Our inaugural drop of
          <span> 3,010 NORDEK NFTs </span>
          has already been snatched up, featuring a collection of over
          <span> 450 unique traits </span>
          assigned programmatically, ensuring each model's individuality.
        </p>
      </div>
      <img src={dropboxImg} alt="dropbox" className=""/>
    </div>
  );
};

export default Dropbox;
