import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import NavImg from "../../assets/nav-img.png";
import { fetchUserNFTs } from "../../web3/ether";

import matches from "../../assets/match.json"

const Index = () => {
  const [nfts, setNfts] = useState([]);
  const [allMatches, setAllMatches] = useState([]);


  const getNfts = async () => {
    try {
      const data = await fetchUserNFTs();
      console.log(data);
      setNfts(data);
    } catch (error) {}
  };

  useEffect(() => {
    getNfts();
    setAllMatches(matches?.matches)
  }, []);

  const findCategory = (num) => {
    return allMatches?.find(item => num > parseInt(item.category) && num <= parseInt(item.category) + 30);
  }

  return (
    <section className="bg-img px-4 sm:px-10 py-10 container-fluid mh-100">
      <div className="col-11 mx-auto">
        <div className="hero-nav flex justify-between">
          <img src={NavImg} alt="" />
          <button>
            <Link to="/">Back</Link>
          </button>
        </div>
        <div className="text-center mt-20">
          <div>
            <h1 className="text-[#fff] text-2xl">My NFTs</h1>
            <p className="text-[#999999]">
              Revisit your  previous purchases and relive the special moments
              associated with each acquisition
            </p>
          </div>
          <div className="flex flex-col justify-center items-center">
            <div className="card-1 mx-auto mt-10">
              {nfts.length > 0 &&
                nfts.map((link, index) => {
                  const url = link;
                  const split = url.split("link/")[1]
                  const tokenID = split.split(".png")[0]
                  const getMatchDetails = findCategory(tokenID)
                  return(
                  <div
                    className={`card py-6 text-[#fff] mb-8 px-3`}
                    key={index}
                  >
                    <p>{`${getMatchDetails.country1} VS ${getMatchDetails.country2}`}</p>
                    <p>{`#${tokenID}`}</p>
                    <img src={link} alt="nft" className="img-fluid" />
                  </div>
                )})}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Index;
