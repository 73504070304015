import React from "react";
import paymentImg from "../../assets/payment-img.png";

const PaymentPlateform = () => {
  return (
    <div className="payment-container">
      <h1>
        Everyone will receive guaranteed
        <span> merchandise </span>
        and exclusive
        <span> NORPAY cards!</span>
      </h1>
      <div className="flex flex-col lg:flex-row items-center lg:justify-around">
        <div className="left-side mt-12 lg:px-16 lg:w-[50%]">
          <h1>
            A crypto
            <span> Payment </span>
            Platform
          </h1>
          <p>
            NORDEK strives to be the most business and
            <span> consumer-friendly </span>
            blockchain ecosystem for mainstream adoption of Web3 payments.
            NORDEK is powered by fast and low-cost
            <span> EVM-compatible </span>
            NRK Network blockchain.
          </p>
        </div>
        <img src={paymentImg} alt="payment" className="overflow-hidden" />
      </div>
    </div>
  );
};

export default PaymentPlateform;
