import React from "react";
import bonusImg from "../../assets/phone.png";

const BonusNft = () => {
  return (
    <div className="dropbox-section flex flex-col-reverse lg:flex-row items-center lg:justify-around mt-12">
      <img src={bonusImg} alt="Bonus Nft" className="overflow-hidden z-50" />
      <div className="left-sdie lg:mt-36 lg:w-[50%] ">
        <div className="font-[Barlow] font-bold">
          <h1 className="heading">NORDEK'S</h1>
          <h1 className="subheading">NFT BONUS</h1>
        </div>
        <p>
          With each purchase, you stand a chance to win exciting rewards like an
          <span> iPhone  </span>
          and
          <span> exclusive </span>
          <span> tickets </span>
          to cricket matches!
        </p>
      </div>
    </div>
  );
};

export default BonusNft;
