import React from "react";
import { Link } from "react-router-dom";

import NavImg from "../../assets/nav-img.png";
import TopBanner from "./top-banner";
import Nfts from "./Nfts";

const PurchaseIndex = () => {
  return (
    <section className="bg-img px-10 py-10">
      <div className="hero-nav flex justify-between">
        <img src={NavImg} alt="" />
        <button>
          <Link to="/">back</Link>
        </button>
      </div>
      <div className="flex flex-col items-center text-center lg:w-1/2 mx-auto gap-3 mt-20">
        <TopBanner />
        <div className="middle-banner">
          Select your Preferred Match, Acquire NFTs, and Secure Confirmed
          Rewards Plus Exclusive Tickets to Your Favorite Team's Matches!
        </div>
        <div className="mt-16">
          <Nfts />
        </div>
      </div>
    </section>
  );
};

export default PurchaseIndex;
