import React from "react";
import BuyNft from './BuyNft';
import netherlands from "../../../assets/country-img/netherlands.png";
import srilanka from "../../../assets/country-img/srilanka.png";
import australia from "../../../assets/country-img/australia.png";
import southafrica from "../../../assets/country-img/southafrica.png";
import { Link } from "react-router-dom";
import TopBanner from "../../purchase-nft/top-banner";

const PurchseNft = (props) => {
  return (
    <div
      className="flex flex-col items-center text-center lg:w-1/2 mx-auto gap-3"
      ref={props.refExplore}
    >
      <TopBanner date="Monday, 16 October" />
      <div className="w-full">
        <BuyNft
          date="Monday, 16 October"
          istTime="2023-10-16T08:30:00.000Z"
          img1={australia}
          country1="Australia"
          img2={srilanka}
          country2="Sri Lanka"
          category="1"
          set={1}
          mintNFT={true}
        />
        <BuyNft
          date="Tuesday, 17 October"
          img1={southafrica}
          country1="South Africa"
          img2={netherlands}
          country2="Netherlands"
          category="31"
          mintNFT={true}
        />
      </div>
      <button className="underline text-[#fff]">
        <Link to="/purchase-nft">See More</Link>
      </button>
    </div>
  );
};

export default PurchseNft;
