import React, { useRef } from "react";
import Hero from "./Hero";
import "../../styles/Home.css";
import Dropbox from "./Dropbox";
import BonusNft from "./BonusNft";
import PaymentPlateform from "./PaymentPlateform";
import PurchseNft from "./Purchase/PurchseNft";
import Claim from "./claim/Claim";

const MainHome = () => {
  const refExplore = useRef(null);
  return (
    <main className="container-fluid px-0 bg-img">
      <section className="hero-bg py-10">
        <Hero refExplore={refExplore} />
      </section>
      <section className="dropbox-bg px-10 py-16">
        <Dropbox />
      </section>
      <section className="bonus-bg px-10 py-12">
        <BonusNft />
      </section>
      <section className="payment-bg px-10 pt-20 pb-40">
        <PaymentPlateform />
      </section>
      <section className="bg-black px-10 py-16">
        <PurchseNft refExplore={refExplore}/>
      </section>
      <section className="bg-black px-10 py-16 mx-auto">
        <Claim />
      </section>
    </main>
  );
};

export default MainHome;
