import React from "react";

const Claim = () => {
  return (
    <>
      <div className="flex flex-col items-center text-center">
        <div>
          <h1 className="heading mb-6">HOW TO CLAIM</h1>
          <p className="text-[#999999]">
            Here's a simple step-by-step guide to help you claim your coveted
            rewards effortlessly
          </p>
        </div>
        <div className=" w-full sm:w-3/4 mt-10">
          <div className="flex text-left border-2 border-[#999999] min-h-[60px] rounded-lg mt-2">
            <div className="step-box h-auto">Step 1</div>
            <div className="step-box-1 h-auto ps-1 py-2">
              <p className="text-[#999999]">
                Buy 4000 NRK from MEXC exchange, transfer to your wallet.
              </p>
            </div>
          </div>

          <div className="flex text-left border-2 border-[rgb(153,153,153)] min-h-[60px] rounded-lg mt-2">
            <div className="step-box h-auto">Step 2</div>
            <div className="step-box-1 h-auto ps-1 py-2">
              <p className="text-[#999999]">
                Connect your wallet, Buy the most coveted NFT for your preferred
                match.
              </p>
            </div>
          </div>
          <div className="flex text-left border-2 border-[#999999] min-h-[60px] rounded-lg mt-2">
            <div className="step-box h-auto">Step 3</div>
            <div className="step-box-1 h-auto ps-1 py-2">
              <p className="text-[#999999]">
                Nordek will acknowledge the purchase & assign you your amazing
                NFT related to the match.
              </p>
            </div>
          </div>
          <div className="flex text-left border-2 border-[#999999] min-h-[60px] rounded-lg mt-2">
            <div className="step-box h-auto">Step 4</div>
            <div className="step-box-1 h-auto ps-1 py-2">
              <p className="text-[#999999]">
                Nordek would contact you within a short window about the prize
                that you have won. Every NFT holder will get a fan jersey and a
                Norpay card. Additionally, 4 NFT holders who have received
                captain and vice captain nfts of a particular match will be
                getting Signed goodies and match tickets. One lucky NFT holder
                who has received NFT of Man of match for that particular match
                will get a chance to win an iPhone.
              </p>
            </div>
          </div>
          <div className="flex text-left border-2 border-[#999999] min-h-[60px] rounded-lg mt-2">
            <div className="step-box h-auto">Step 5</div>
            <div className="step-box-1 h-auto ps-1 py-2">
              <p className="text-[#999999]">
                After collecting your details, Nordek would arrange to
                deliver your prize.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-10 mt-5 mx-auto text-center">
        <p className="text-[#999999]">
          Disclaimer: The NFTs sold on this platform are not for US citizens and
          any other sanctioned countries where purchasing / minting/holding an
          NFT is illegal, if any user mints from any such areas, the
          Organization and the platform is not responsible for the benefits and
          the rewards associated.
        </p>
      </div>
    </>
  );
};

export default Claim;
