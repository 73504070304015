import React from "react";
import NavImg from "../../assets/nav-img.png";
import wc2023 from "../../assets/wc2023.png";
import { Link } from "react-router-dom";

const Hero = (props) => {
  return (
    <div className="container-fluid">
      <div className="hero-nav flex justify-between">
        <img src={NavImg} alt="" />
        <button>
          <Link to="/my-nft">My NFT</Link>
        </button>
      </div>
      <div className="flex flex-col items-center lg:justify-around lg:flex-row mt-10">
        <div className="mt-12 md:px-16 lg:w-[50%]">
          <h1 className="mb-4 heading">NORDEK NFTs</h1>
          <p className="mb-6 text-sm text-[#fff]">
            Unlock the Ultimate Cricket Universe with Nordek! 🚀 Unite with
            fellow enthusiasts in a thrilling journey where Cricket Passion
            meets NFT Collectibles! 🏏✨ Gain exclusive access to rare cricket
            memorabilia in a vibrant virtual and real world, specially crafted
            for YOU, during the Cricket World Cup 2023! 🌎🏆 Be part of the
            legacy by minting your first Cricket NFT!
          </p>
          <button
            className="explore-btn"
            onClick={() => {
              props.refExplore.current.scrollIntoView();
            }}
          >
            Explore More!
          </button>
        </div>
        <img src={wc2023} alt="hero" className="overflow-hidden" />
      </div>
      <div className="hero-bottom">
        <div className="col-md-8 mx-auto">
          <h1 className="text-[#353434] text-center mt-12 text-lg">
            Mint NFTs on <span> NORDEK Blockchain</span> and get exclusive
            rewards and match tickets. Also stand a chance to meet players and
            get unique rewards like <span>iPhone, Norpay Cards</span> and{" "}
            <span>special airdrops</span>.
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Hero;
