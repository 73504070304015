import React from // , { useEffect, useState }
"react";

const TopBanner = ({ date }) => {
  // const [timeRemaining, setTimeRemaining] = useState(0);

  // const set = 1;
  // useEffect(() => {
  //   if (set === 1) {
  //     const targetTimeIST = new Date("2023-10-16T12:30:00Z");
  //     const nowIST = new Date();
  //     const timeDifference = targetTimeIST - nowIST;

  //     if (timeDifference > 0) {
  //       const interval = setInterval(() => {
  //         const updatedTimeDifference = targetTimeIST - new Date();

  //         if (updatedTimeDifference <= 0) {
  //           clearInterval(interval);
  //           setTimeRemaining(0);
  //         } else {
  //           setTimeRemaining(updatedTimeDifference);
  //         }
  //       }, 1000);

  //       return () => clearInterval(interval);
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [date]);

  // const formatTime = (milliseconds) => {
  //   const hours = Math.floor(milliseconds / 3600000);
  //   const minutes = Math.floor((milliseconds % 3600000) / 60000);
  //   const seconds = Math.floor((milliseconds % 60000) / 1000);

  //   const formattedHours = String(hours).padStart(2, "0");
  //   const formattedMinutes = String(minutes).padStart(2, "0");
  //   const formattedSeconds = String(seconds).padStart(2, "0");

  //   return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  // };

  return (
    <div>
      <h1 className="text-[#fff] text-[32px] text-medium mb-2">
        Buy NFTs now for all the matches. Surprise giveaways for early minting.
      </h1>
      <p className="text-[#999999] text-lg mb-6">
        We're here to support Cricket fanatics. Irrespective of the team and the
        match, everyone will get exclusive benefits for the first edition of the
        NFTs. Mint now for 4000 NRK.
      </p>
      {/* {timeRemaining > 0 && timeRemaining < 86400000 && (
        <div className="text-white text-xl font-semibold">
          Mint NFTs will start in: {formatTime(timeRemaining)}
        </div>
      )} */}
    </div>
  );
};

export default TopBanner;
