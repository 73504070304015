import React
, { useEffect, useState }// { useState, useEffect }
 from "react";
 import {useNavigate} from 'react-router-dom'
import CountryCard from "./CountryCard";
import { Mint, checkNFTSold } from "../../../web3/ether";


const BuyNft = ({
  date,
  set,
  img1,
  img2,
  country1,
  country2,
  category,
  mintNFT,
}) => {
  // const [timeRemaining, setTimeRemaining] = useState(0);

  // useEffect(() => {
  //   if (set === 1) {
  //     const targetTimeIST = new Date("2023-10-16T12:30:00Z");
  //     const nowIST = new Date();
  //     const timeDifference = targetTimeIST - nowIST;

  //     if (timeDifference > 0) {
  //       const interval = setInterval(() => {
  //         const updatedTimeDifference = targetTimeIST - new Date();

  //         if (updatedTimeDifference <= 0) {
  //           clearInterval(interval);
  //           setTimeRemaining(0);
  //         } else {
  //           setTimeRemaining(updatedTimeDifference);
  //         }
  //       }, 1000);

  //       return () => clearInterval(interval);
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [date]);


  // const formatTime = (milliseconds) => {
  //   const hours = Math.floor(milliseconds / 3600000);
  //   const minutes = Math.floor((milliseconds % 3600000) / 60000);
  //   const seconds = Math.floor((milliseconds % 60000) / 1000);

  //   const formattedHours = String(hours).padStart(2, "0");
  //   const formattedMinutes = String(minutes).padStart(2, "0");
  //   const formattedSeconds = String(seconds).padStart(2, "0");

  //   return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  // };
  const [categoryId,setCategoryId]= useState([])
  const [soldoutcheck,setSoldoutcheck]= useState(false)
  const navigate = useNavigate()
  useEffect(()=>{
     const response = async()=>{
      if(category){
        const res = await checkNFTSold(category)
       console.log("res",res)
       if(res){
        const soldNftRecords = res?.length ? res.map(records=>parseInt(records._hex)) : []
        setCategoryId(soldNftRecords)
       }else{
        setSoldoutcheck(true)
       }
      }
    }
    response()
  },[category])
  const handleClick = async () => {
    try {
      if(soldoutcheck){
       alert("Please change your wallet network to Nordek, Refresh the page & try again!")
      }else{
      await Mint(category);
      
      
      navigate('/my-nft')
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className="card p-6 text-[#fff] mb-8">
      <div className="mb-4">{date}</div>
      <div className="flex justify-center items-center gap-2 sm:gap-20">
        <CountryCard countryName={country1} img={img1} />
        <h4>vs</h4>
        <CountryCard countryName={country2} img={img2} />
      </div>
      <div>
        {/* {mintNFT && ( */}
          <button className="buy-btn" disabled={!categoryId.length && !soldoutcheck} onClick={handleClick}>
            {!soldoutcheck && !categoryId.length ? 'Sold Out': 'MINT NFT'}
          </button>
        {/* // )} */}
        {/* {!mintNFT && <button className="buy-btn">Coming Soon</button>} */}
        {/* {timeRemaining > 0 && timeRemaining < 86400000 && (

          <div className="mb-2">
            Time Remaining: {formatTime(timeRemaining)}
          </div>
        )} */}
      </div>
    </div>
  );
};

export default BuyNft;
