import React from "react";

const CountryCard = ({ countryName, img }) => {
  return (
    <div className="country-card">
      <img src={img} alt="" className="img-fluid"/>
      <h1>{countryName} </h1>
    </div>
  );
};

export default CountryCard;
