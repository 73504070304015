import React from "react";

// import BuyNft from "../Home/Purchase/BuyNft";
import BuyNft from "../Home/Purchase/BuyNft";
import england from "../../assets/country-img/england.png";
import newzealand from "../../assets/country-img/newzealand.png";
import pakistan from "../../assets/country-img/pakistan.png";
import netherlands from "../../assets/country-img/netherlands.png";
import bangladesh from "../../assets/country-img//bangladesh.png";
import afganistan from "../../assets/country-img/afganistan.png";
import srilanka from "../../assets/country-img/srilanka.png";
import australia from "../../assets/country-img/australia.png";
import india from "../../assets/country-img/india.png";
import southafrica from "../../assets/country-img/southafrica.png";

const Nfts = () => {
  return (
    <>
      <BuyNft
        date="Monday, 16 October"
        istTime="2023-10-16T08:30:00.000Z"
        img1={australia}
        country1="Australia"
        img2={srilanka}
        country2="Sri Lanka"
        category="1"
        set={1}
        mintNFT={true}
      />
      <BuyNft
        date="Tuesday, 17 October"
        img1={southafrica}
        country1="South Africa"
        img2={netherlands}
        country2="Netherlands"
        category="31"
        mintNFT={true}
      />
      <BuyNft
        date="Wednesday, 18 October"
        img1={newzealand}
        country1="New Zealand"
        img2={afganistan}
        country2="Afganistan"
        category="61"
        mintNFT={true}
      />
      <BuyNft
        date="Thursday, 19 October"
        img1={india}
        country1="India"
        img2={bangladesh}
        country2="Bangladesh"
        category="91"
        mintNFT={true}
      />
      <BuyNft
        date="Friday, 20 October"
        img1={australia}
        country1="Australia"
        img2={pakistan}
        country2="Pakistan"
        category="121"
        mintNFT={true}
      />
      <BuyNft
        date="Saturday, 21 October"
        img1={netherlands}
        country1="Netherlands"
        img2={srilanka}
        country2="Sri Lanka"
        category="151"
        mintNFT={true}
      />
      <BuyNft
        date="Saturday, 21 October"
        img1={england}
        country1="England"
        img2={southafrica}
        country2="South Africa"
        category="181"
        mintNFT={true}
      />
      <BuyNft
        date="Sunday, 22 October"
        img1={india}
        country1="india"
        img2={newzealand}
        country2="New Zealand"
        category="211"
        mintNFT={true}
      />
      <BuyNft
        date="Monday, 23 October"
        img1={pakistan}
        country1="Pakistan"
        img2={afganistan}
        country2="Afganistan"
        category="241"
        mintNFT={true}
      />
      <BuyNft
        date="Tuesday, 24 October"
        img1={southafrica}
        country1="South Africa"
        img2={bangladesh}
        country2="Bangladesh"
        category="271"
        mintNFT={true}
      />
      <BuyNft
        date="Wednesday, 25 October"
        img1={australia}
        country1="Australia"
        img2={netherlands}
        country2="Netherlands"
        category="301"
        mintNFT={true}
      />
      <BuyNft
        date="Thursday, 26 October"
        img1={england}
        country1="England"
        img2={srilanka}
        country2="Sri Lanka"
        category="331"
        mintNFT={true}
      />
      <BuyNft
        date="Friday, 27 October"
        img1={pakistan}
        country1="Pakistan"
        img2={southafrica}
        country2="South Africa"
        category="361"
        mintNFT={true}
      />
      <BuyNft
        date="Saturday, 28 October"
        img1={australia}
        country1="Australia"
        img2={newzealand}
        country2="New Zealand"
        category="391"
        mintNFT={true}
      />
      <BuyNft
        date="Saturday, 28 October"
        img1={netherlands}
        country1="Netherlands"
        img2={bangladesh}
        country2="Bangladesh"
        category="421"
        mintNFT={true}
      />
      <BuyNft
        date="Sunday, 29 October"
        img1={india}
        country1="India"
        img2={england}
        country2="England"
        category="451"
        mintNFT={true}
      />
      <BuyNft
        date="Monday, 30 October"
        img1={afganistan}
        country1="Afganistan"
        img2={srilanka}
        country2="Sri Lanka"
        category="481"
        mintNFT={true}
      />
      <BuyNft
        date="Tuesday, 31 October"
        img1={pakistan}
        country1="Pakistan"
        img2={bangladesh}
        country2="Bangladesh"
        category="511"
        mintNFT={true}
      />
      <BuyNft
        date="Wednesday, 1 November"
        img1={newzealand}
        country1="New Zealand"
        img2={southafrica}
        country2="South Africa"
        category="541"
        mintNFT={true}
      />
      <BuyNft
        date="Thursday, 2 November"
        img1={india}
        country1="India"
        img2={srilanka}
        country2="Sri Lanka"
        category="571"
        mintNFT={true}
      />
      <BuyNft
        date="Friday, 3 November"
        img1={netherlands}
        country1="Netherlands"
        img2={afganistan}
        country2="Afganistan"
        category="601"
        mintNFT={true}
      />
      <BuyNft
        date="Saturday, 4 November"
        img1={newzealand}
        country1="New Zealand"
        img2={pakistan}
        country2="Pakistan"
        category="631"
        mintNFT={true}
      />
      <BuyNft
        date="Saturday, 4 November"
        img1={england}
        country1="England"
        img2={australia}
        country2="Australia"
        category="661"
        mintNFT={true}
      />
      <BuyNft
        date="Sunday, 5 November"
        img1={india}
        country1="India"
        img2={southafrica}
        country2="South Africa"
        category="691"
        mintNFT={true}
      />
      <BuyNft
        date="Monday, 6 November"
        img1={bangladesh}
        country1="Bangladesh"
        img2={srilanka}
        country2="Sri Lanka"
        category="721"
        mintNFT={true}
      />
      <BuyNft
        date="Tuesday, 7 November"
        img1={australia}
        country1="Australia"
        img2={afganistan}
        country2="Afganistan"
        category="751"
        mintNFT={true}
      />
      <BuyNft
        date="Wednesday, 8 November"
        img1={england}
        country1="England"
        img2={netherlands}
        country2="Netherlands"
        category="781"
        mintNFT={true}
      />
      <BuyNft
        date="Thursday, 9 November"
        img1={newzealand}
        country1="New Zealand"
        img2={srilanka}
        country2="Sri Lanka"
        category="811"
        mintNFT={true}
      />
      <BuyNft
        date="Friday, 10 November"
        img1={southafrica}
        country1="South Africa"
        img2={afganistan}
        country2="Afganistan"
        category="841"
        mintNFT={true}
      />
      <BuyNft
        date="Saturday, 11 November"
        img1={australia}
        country1="Australia"
        img2={bangladesh}
        country2="Bangladesh"
        category="871"
        mintNFT={true}
      />
      <BuyNft
        date="Saturday, 11 November"
        img1={england}
        country1="England"
        img2={pakistan}
        country2="Pakistan"
        category="901"
        mintNFT={true}
      />
      <BuyNft
        date="Sunday, 12 November"
        img1={india}
        country1="India"
        img2={netherlands}
        country2="Netherlands"
        category="931"
        mintNFT={true}
      />
    </>
  );
};

export default Nfts;
